export type TextColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'link'
  | 'white'
  | 'success'
  | 'blue'
  | 'action'
  | 'secondary-negative'

export type TextWeight = 'regular' | 'bold'

export function colorStyle(color: TextColor | undefined): string[] {
  switch (color) {
    case 'primary':
      return ['text-color-primary']
    case 'secondary':
      return ['text-color-secondary']
    case 'secondary-negative':
      return ['text-color-secondary-negative']
    case 'blue':
      return ['text-color-blue']
    case 'action':
      return ['text-color-action']
    case 'error':
      return ['text-color-error']
    case 'link':
      return ['text-color-link']
    case 'white':
      return ['text-color-white']
    case 'success':
      return ['text-color-success']
    default:
      return []
  }
}

export function weightStyle(color: TextWeight | undefined): string[] {
  switch (color) {
    case 'regular':
      return ['text-weight-regular']
    case 'bold':
      return ['text-weight-bold']
    default:
      return []
  }
}

export function overrides(input: {
  color?: TextColor
  weight?: TextWeight
}): string[] {
  return [...colorStyle(input.color), ...weightStyle(input.weight)]
}

export interface TextColorProp {
  color?: TextColor
}

export interface TextWeightProp {
  weight?: TextWeight
}
