import cls from 'classnames'
import React from 'react'

import { TextColorProp, TextWeightProp, overrides } from '../lib'

interface BodySmallProps extends TextWeightProp, TextColorProp {
  className?: string
}

const BodySmall: React.FC<React.PropsWithChildren<BodySmallProps>> = ({
  children,
  className,
  color,
  weight,
}) => {
  return (
    <p
      className={cls('body-small', className, ...overrides({ color, weight }))}
    >
      {children}
    </p>
  )
}

export default BodySmall
